/*eslint-disable*/
import React, { useState } from "react";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { useMedia } from "use-media";
import TopFormWithImage from "../../components/TopFormCenterImage";

import {
  VideoHowtoIcons,
  VideoUsecasesIcon,
  VideoTrainingIcon,
  VideoProductIcon,
  VideoAllIcon,
  VideoAppsIcon,
  PlayIcons,
} from "../../react/Icons";
import { data } from "jquery";

const HeaderLeftImg = require("../../assets/images/videos_header.png");
export const cardOne = require("../../assets/images/video_icons/icon_1.png");
export const cardTwo = require("../../assets/images/video_icons/icon_2.png");
export const cardThree = require("../../assets/images/video_icons/icon_3.png");
export const cardFour = require("../../assets/images/video_icons/icon_4.png");
export const cardFive = require("../../assets/images/video_icons/icon_5.png");
export const cardSix = require("../../assets/images/video_icons/icon_6.png");

export const Image = require("../../assets/images/video_icons/dummy_image.png");

export default function MarketPlace(props) {
  return (
    <>
      <TitleAndMetaTags
        title="Free Chatbot & Automation Videos | Watch How to Videos of Workativ Assistant - HR & IT Helpdesk Chatbot"
        description="Conversational AI and Process Automation Videos. Learn how to build chatbot with process automation."
        keywords={[
          "IT Helpdesk Chatbot",
          "HR Chatbot",
          "Workativ Integrations",
        ]}
        ogTitle="Free Chatbot & Automation Videos | Watch How to Videos of Workativ Assistant - HR & IT Helpdesk Chatbot"
        ogDescription="Conversational AI and Process Automation Videos. Learn how to build chatbot with process automation."
      />
      <Container>
        <div className="w-100 float-left videos_wrapper">
          <Layout backgroundColor={"bg_videos"} logoFor="ASSISTANT">
            {/* <section className="assistant_slider trial_mb_80 bg_market_webinar bg_video">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                    <div className="main-slider-left">
                      <h1>
                        Know more about the innovative capabilities of Workativ
                        Assistant and transform your workplace support
                      </h1>
                      <p>Subscribe now to watch 100+ videos</p>
                      <div className="footer_form">
                        <RequestForm isFooterForm={false} />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 bg_video_img">
                    <div className="main-slider-right assistant_right_img beta-slider">
                      <img src={HeaderLeftImg} alt={HeaderLeftImg} />
                    </div>
                  </div>
                </div>
              </div>
            </section>{" "} */}
            <VideoPlayWrapper />
            {/* <CategoryVideos /> */}
            {/* <VideosWrapper /> */}
            <OverviewWrapper />
            <SimiliarVideosWrapper />
            <RequestForm isFooterForm={true} />
          </Layout>
        </div>
      </Container>
    </>
  );
}

const CategoryVideos = () => {
  return (
    <div className="category_wrapper">
      <div className="container">
        <h3>Videos by Category</h3>
        <div className="category_lsiting">
          <div className="category_lsiting_card">
            <img src={cardOne} alt="" />
            <h4>How to</h4>
            <h6>36+ Videos</h6>
          </div>
          <div className="category_lsiting_card">
            <img src={cardTwo} alt="" />
            <h4>Usecases</h4>
            <h6>23+ Videos</h6>
          </div>
          <div className="category_lsiting_card">
            <img src={cardThree} alt="" />
            <h4>Training</h4>
            <h6>19+ Videos</h6>
          </div>
          <div className="category_lsiting_card">
            <img src={cardFour} alt="" />
            <h4>Product Videos</h4>
            <h6>15+ Videos</h6>
          </div>
          <div className="category_lsiting_card">
            <img src={cardFive} alt="" />
            <h4>Applications</h4>
            <h6>23+ Videos</h6>
          </div>
          <div className="category_lsiting_card">
            <img src={cardSix} alt="" />
            <h4>All Videos</h4>
            <h6>103+ Videos</h6>
          </div>
        </div>
      </div>
    </div>
  );
};
const MenuLists = [
  {
    icon: <VideoAllIcon />,
    menu: "All Videos",
    count: "(103)",
    id: "1",
  },
  {
    icon: <VideoHowtoIcons />,
    menu: "How to",
    count: " (36) ",
    id: "2",
  },

  {
    icon: <VideoUsecasesIcon />,
    menu: "Usecases ",
    count: "(23)",
    id: "3",
  },
  {
    icon: <VideoTrainingIcon />,
    menu: "Training ",
    count: "(19)",
    id: "4",
  },
  {
    icon: <VideoProductIcon />,
    menu: "Product Videos ",
    count: "(15)",
    id: "5",
  },
  {
    icon: <VideoAppsIcon />,
    menu: "Applications",
    count: "(23)",
    id: "6",
  },
];

const VideosWrapper = () => {
  const [isOpen, setOpen] = useState(false);

  const isSmall = useMedia({ maxWidth: "992px" });

  return (
    <div className="videos_container">
      <div className="container">
        {isSmall ? <MobileDropdown /> : <VideosList />}
        <YoutubeVideos />
      </div>
    </div>
  );
};

const VideosList = () => {
  const [active, setActive] = useState(false);

  return (
    <section className="video_lists col-12 col-lg-3 ">
      <div className="video_lists_lg">
        <div className="video_lists_container">
          <ul className="video_list_ul">
            <div className="video_list_ul_header">Category</div>
            {MenuLists.map((list) => (
              <li
                key={list.id}
                onClick={() => setActive(!active)}
                className={
                  !active
                    ? "video_list_li video_list_li_active"
                    : "video_list_li "
                }
              >
                <div className="video_svg_iocn">{list.icon}</div>
                <div className="video_content_li">
                  {list.menu} <span>{list.count}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

const YoutubeVideos = () => {
  return (
    <div className="videos_container">
    <section className="utube_videos col-12 col-lg-9 ">
      <div className="search_marketpalce">
        <input
          className="search_click"
          type="search"
          placeholder="Search Workflows"
        />
        <span className="search_icon">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fal"
            data-icon="search"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            class="svg-inline--fa fa-search fa-w-16 fa-2x"
          >
            <path
              fill="currentColor"
              d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z"
            ></path>
          </svg>
        </span>
      </div>
      <div className="utube_rows">
        <div className="utube_col_4">
          <div className="utube_iframe">
            <div className="position-relative w-100 h-100">
              <span>
                <PlayIcons />
              </span>
              <img src={Image} />
            </div>
            <p className="utube_content">
              AI Conversational Bot for IT Help desk - conversational dialogs
            </p>
          </div>
        </div>
        <div className="utube_col_4">
          <div className="utube_iframe">
            <div className="position-relative w-100 h-100">
              <span>
                <PlayIcons />
              </span>
              <img src={Image} />
            </div>
            <p className="utube_content">
              AI Conversational Bot for IT Help desk - conversational dialogs
            </p>
          </div>
        </div>
        <div className="utube_col_4">
          <div className="utube_iframe">
            <div className="position-relative w-100 h-100">
              <span>
                <PlayIcons />
              </span>
              <img src={Image} />
            </div>
            <p className="utube_content">
              AI Conversational Bot for IT Help desk - conversational dialogs
            </p>
          </div>
        </div>
        <div className="utube_col_4">
          <div className="utube_iframe">
            <div className="position-relative w-100 h-100">
              <span>
                <PlayIcons />
              </span>
              <img src={Image} />
            </div>
            <p className="utube_content">
              AI Conversational Bot for IT Help desk - conversational dialogs
            </p>
          </div>
        </div>
        <div className="utube_col_4">
          <div className="utube_iframe">
            <div className="position-relative w-100 h-100">
              <span>
                <PlayIcons />
              </span>
              <img src={Image} />
            </div>
            <p className="utube_content">
              AI Conversational Bot for IT Help desk - conversational dialogs
            </p>
          </div>
        </div>
        <div className="utube_col_4">
          <div className="utube_iframe">
            <div className="position-relative w-100 h-100">
              <span>
                <PlayIcons />
              </span>
              <img src={Image} />
            </div>
            <p className="utube_content">
              AI Conversational Bot for IT Help desk - conversational dialogs
            </p>
          </div>
        </div>
        <div className="utube_col_4">
          <div className="utube_iframe">
            <div className="position-relative w-100 h-100">
              <span>
                <PlayIcons />
              </span>
              <img src={Image} />
            </div>
            <p className="utube_content">
              AI Conversational Bot for IT Help desk - conversational dialogs
            </p>
          </div>
        </div>
        <div className="utube_col_4">
          <div className="utube_iframe">
            <div className="position-relative w-100 h-100">
              <span>
                <PlayIcons />
              </span>
              <img src={Image} />
            </div>
            <p className="utube_content">
              AI Conversational Bot for IT Help desk - conversational dialogs
            </p>
          </div>
        </div>
      </div>
      <div className="show_more_videos">
        <button>Show More Videos</button>
      </div>
    </section>
    </div>
  );
};

//Dropdown for Mobile
export const MobileDropdown = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <section className="mobile_dropdown">
      <div className="container">
        <div className="select_application">
          <div className="select_work_form" onClick={(e) => setIsOpen(!isOpen)}>
            <h4>Category</h4>
            <input type="text" readOnly onChange={(e) => {}} />
            <div className="arrow_up_form">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 5.75">
                <title />
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <path d="M5,5.75H5a.78.78,0,0,1-.53-.21L.22,1.29a.74.74,0,0,1,0-1,.75.75,0,0,1,1,0L5,4,8.74.22a.74.74,0,0,1,1,1L5.51,5.54A.78.78,0,0,1,5,5.75Z" />
                  </g>
                </g>
              </svg>
            </div>
            {isOpen == true ? (
              <div
                className="automation_scroly"
                role="combobox"
                aria-expanded="true"
                aria-haspopup="listbox"
                aria-owns="downshift-5-menu"
                aria-labelledby="downshift-5-label"
              >
                <ul>
                  {MenuLists.map((data) => (
                    <li
                      className="video_list_li_mob"
                      onClick={(e) => {
                        setIsOpen(false);
                        data.onclick();
                      }}
                    >
                      {data.menu}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

const VideoPlayWrapper = () => {
  return (
    <div className="back_features_hide cc_teams_wrapper">
      <section className="main-slider feature_inner_page  bg_feature_slider bg_feature_slider_wrapper trial_page_img">
        <div className="feature_bg_img bg_videos" />
        <div className="container">
          <div className="row">
            <div className="back_features">
              <div className="back_features_page">
                <a
                  href="/conversational-ai-platform/features"
                  className="url_manipulation"
                >
                  <span>←</span>Back to Videos
                </a>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="main-slider-left">
                <h1>
                  Enhance your Freshdesk experience with Slack or Microsoft
                  Teams chatbot and workflow automation, from Workativ.
                </h1>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 features_change_img_header pl-0 pr-0">
            <div className="features_change_img">
              <div className="features_change_img-center">
                <iframe
                  src="https://www.youtube.com/embed/DNaVY8rh0PQ?rel=0&amp;controls=1&amp;showinfo=0&autoplay=1&mute=1"
                  allow="autoplay; encrypted-media"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                  allowtransparency="true"
                  style="background: #FFFFFF;"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const OverviewWrapper = () => {
  return (
    <div className="overview_wrapper">
      <section class="skit_contactus sign_up-form_us demo_page">
        <div class="container signup_form">
          <div class="row beta_change_form">
            <div class="col-lg-8 col-md-12 col-sm-12 col-xs-12 ">
              <h3>An overview</h3>
              <p>
                Combining the Freshdesk ticketing tool with Workativ Assistant,
                you make sure your employees have access to an interactive IT
                self-service interface instead of static portals. Let’s take a
                look at what all you can do with Freshdesk + Workativ Assistant.
              </p>
              <h6>
                Freshdesk actions that can be automated with Workativ Assistant
              </h6>
              <ul>
                <li>Creating a Freshdesk support ticket</li>
                <li> Retrieving a Freshdesk ticket’s details</li>
                <li>Updating a Freshdesk ticket</li>
                <li>Deleting a Freshdesk ticket</li>
                <li>Adding new contact</li>
                <li>Updating a contact’s details</li>
                <li>Removing a contact</li>
              </ul>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12 demo_page_right">
              <div className="overview_right_wrpper">
                <ResourcesWrapper />
                <GetStartedWrapper />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const ResourcesWrapper = () => {
  return (
    <div className="resource_wrapper">
      <h6>Resources</h6>
      <ul>
        <li>Using Workativ Assistant to integrate Freshdesk with other apps</li>
        <li>
          10 Ways to Use AI Chatbots for Internal IT and HR Support – Workativ
        </li>
      </ul>
    </div>
  );
};

const GetStartedWrapper = () => {
  return (
    <div className="get_started_wrapper">
      <h5>
        Kickstart Your Employee Support Transformation With Workativ’s IT Help
        Desk Chatbot Today!
      </h5>
      <button>Get Started</button>
    </div>
  );
};

const SimiliarVideosWrapper = () => {
  return (
    <section className="similar_videos_wrapper">
    <div className="utube_videos col-12 col-lg-12 ">
      <div className="container">
        <div className="row">
          <h3>Similar videos you might like</h3>
      <div className="utube_rows">
        <div className="utube_col_4">
          <div className="utube_iframe">
            <div className="position-relative w-100">
              <span>
                <PlayIcons />
              </span>
              <img src={Image} />
            </div>
            <p className="utube_content">
              AI Conversational Bot for IT Help desk - conversational dialogs
            </p>
          </div>
        </div>
        <div className="utube_col_4">
          <div className="utube_iframe">
            <div className="position-relative w-100">
              <span>
                <PlayIcons />
              </span>
              <img src={Image} />
            </div>
            <p className="utube_content">
              AI Conversational Bot for IT Help desk - conversational dialogs
            </p>
          </div>
        </div>
        <div className="utube_col_4">
          <div className="utube_iframe">
            <div className="position-relative w-100">
              <span>
                <PlayIcons />
              </span>
              <img src={Image} />
            </div>
            <p className="utube_content">
              AI Conversational Bot for IT Help desk - conversational dialogs
            </p>
          </div>
        </div>
      </div>
      </div>
      </div>
    </div>
    </section>
  );
};


